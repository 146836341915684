<template>
	<div class="full-height">
		<div
			class="justify-space-between full-height overflow-y-auto"
		>
			<div class="flex-1 bg-white pa-10 mr-10 ">
				<h6 class="under-line-identify">계정정보</h6>
				<table class="table th-left td-left">
					<col width="120px">
					<col width="auto">
					<col width="120px">
					<col width="auto">
					<tbody>
					<tr>
						<th>아이디</th>
						<td colspan="3">
							{{ item.member_id }}
						</td>
						<th v-if="false">소속</th>
						<td v-if="false" class="position-relative">
							<template
								v-if="user.role == codes.type_code_agency"
							>{{ item.agency_name }}</template>
							<template
								v-else
							>
								<select
									v-model="item.admin_code"
									class="input-box position-relative cursor-pointer"
									style="z-index: 1"
								>
									<option value="">선택하세요</option>
									<option
										v-for="(agency, index) in agency_list"
										:key="'agency_' + index"
										:value="agency.agency_id"
									>{{ agency.agency_name }}</option>
								</select>
								<v-icon
									class="position-absolute color-icon"
									style="right: 15px; top: 11px; z-index: 0"
								>mdi mdi-menu-down</v-icon>
							</template>
						</td>
					</tr>
					<tr>
						<th>이름 <span class="color-red">*</span></th>
						<td>
							<input
								type="text"
								v-model="item.member_name"
								maxlength="20"
								class="input-box full-width"
								placeholder="회원 이름을 입력하세요"
							/>
						</td>
						<th>비밀번호</th>
						<td class="">
							<button
								class="bg-blue-light pa-5-10"
								@click="isPassword"
							>비밀번호 초기화</button>
						</td>
					</tr>
					<tr>
						<th>휴대폰 번호</th>
						<td>
							<input
								type="number"
								v-model="item.member_tell"
								:ruels="[rules.max(item, 'member_tell', 12)]"
								class="input-box full-width"
								placeholder="회원 연락처를 입력하세요"
							/>
						</td>
						<th>보유 포인트</th>
						<td>{{ item.member_point | makeComma}}P <button @click="postRefresh"><v-icon class="color-blue" small>mdi mdi-refresh</v-icon></button></td>
					</tr>
					<tr>
						<th>생년월일</th>
						<td>
							<input
								type="number"
								v-model="item.birthdate"
								:ruels="[rules.max(item, 'birthdate', 8)]"
								class="input-box full-width"
								placeholder="생년월일 입력하세요"
							/>
						</td>
						<th>가입일</th>
						<td>{{ item.join_date }}</td>
					</tr>
					<tr>

						<th>계정상태</th>
						<td colspan="3">
							<button
								v-for="(code, index) in codes.S003.items"
								:key="code.total_code + '_' + index"

								class="pa-5-10 mr-1"
								:class="item.member_status == code.code_value ? 'bg-' + code.code_color: 'bg-default'"
								@click="item.member_status = code.code_value "
							>{{ code.code_name }}</button>
						</td>
					</tr>
					<tr>

						<th>회원등급</th>
						<td colspan="3">
							<button
								v-for="(code, index) in codes.M001.items"
								:key="code.total_code + '_' + index"

								class="pa-5-10 mr-1"
								:class="item.member_level == code.code_index ? 'crown-' + code.code_color: 'bg-default'"
								@click="item.member_level = code.code_index "
							>{{ code.code_name }}</button>
						</td>
					</tr>
					<tr>
						<th>주소 <span class="color-red">*</span></th>
						<td colspan="3">

							<input
								v-model="item.member_post"

								class="input-box-inline mr-10"
								type="text" placeholder="우편번호"
								readonly

								@click="daumPost('company')"
							>

							<button
								class="box pa-5-10 bg-identify"
								@click="daumPost('company')"
							>주소 검색</button>

							<input
								v-model="item.member_addr1"

								class="input-box full-width mt-10" type="text" placeholder="주소"
								readonly

								@click="daumPost('company')"
							>
							<input
								v-model="item.member_addr2"

								class="input-box full-width mt-10" type="text" placeholder="상세주소를 입력하세요."
								maxlength="50"
							>
						</td>
					</tr>
					</tbody>
				</table>

				<div>
					<h6>회원 등급</h6>
					<div>
						<table class="table">
							<thead>
							<tr>
								<th>등급</th>
								<th>가입 기간</th>
								<th>1회 한도</th>
								<th>한달 한도</th>
								<th>비고</th>
							</tr>
							</thead>
							<tbody>
							<tr
								v-for="(item, index) in this.codes.M001.items"
								:key="'item_' + index"
							>
								<th>{{ item.code_name }}</th>
								<td>{{ JSON.parse(item.code_value).condition == '1' ? '-' : JSON.parse(item.code_value).condition > 0 ? JSON.parse(item.code_value).condition + '일 이상' : '-' }}</td>
								<td>{{ JSON.parse(item.code_value).limit == '1' ? '-' : JSON.parse(item.code_value).limit > 0 ? JSON.parse(item.code_value).limit : '무제한' | makeComma }}</td>
								<td>{{ JSON.parse(item.code_value).month == '1' ? '-' : JSON.parse(item.code_value).month > 0 ? JSON.parse(item.code_value).month : '무제한'| makeComma }}</td>
								<td>{{ JSON.parse(item.code_value).condition > 0 ? '자동등업' : JSON.parse(item.code_value).condition == 'admin' ? '수동처리' : '가입즉시'}}</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<div class="flex-1 pa-10 full-height flex-column bg-white overflow-y-auto">
				<h6 class="under-line">
					<button
						class="pa-5-10"
						:class="tab == 1 ? 'bg-identify' : 'bg-white'"
						@click="tab = 1"
					>최근 주문 내역</button>
					<button
						class="pa-5-10"
						:class="tab == 3 ? 'bg-identify' : 'bg-white'"
						@click="tab = 3"
					>포인트 이력</button>
					<button
						class="pa-5-10"
						:class="tab == 5 ? 'bg-identify' : 'bg-white'"
						@click="tab = 5"
					>등급 이력</button>
					<button
						class="pa-5-10"
						:class="tab == 2 ? 'bg-identify' : 'bg-white'"
						@click="tab = 2"
					>배송지 목록</button>
					<button
						class="pa-5-10"
						:class="tab == 4 ? 'bg-identify' : 'bg-white'"
						@click="tab = 4"
					>변경 이력</button>
				</h6>

				<div class="full-height flex-column overflow-y-auto">
					<div
						v-if="tab == 1"
						class="full-height"
					>
						<div class="pa-10">
							월간 포인트 구매 금액: <span class="font-weight-bold">{{ month | makeComma}}P</span>
						</div>
						<div
							v-if="items_order.length > 0"
						>
							<table class="table table-even">
								<thead>
								<tr>
									<th>일시</th>
									<th>주문번호</th>
									<th>결제구분</th>
									<th>결제금액</th>
									<th>주문상태</th>
								</tr>
								</thead>
								<tbody>
								<tr
									v-for="(order, o_index) in list_order"
									:key="'order_' + o_index"
								>
									<td>{{ order.wDate }}</td>
									<td class="text-left">{{ order.order_number }}</td>
									<td>{{ order.pay_div_name }}</td>
									<td>{{ order.order_price | makeComma }} 원</td>
									<td>{{ codes.o_status[order.o_status].name }}</td>
								</tr>
								</tbody>
							</table>
						</div>
						<Empty
							v-else
						></Empty>
					</div>
					<div
						v-else-if="tab == 2"
						class="full-height"
					>
						<div
							v-if="shipping_list.length > 0"
						>
							<table class="table table-even">
								<colgroup>
									<col width="150px"/>
									<col width="150px"/>
									<col width="150px"/>
									<col width="auto"/>
								</colgroup>
								<thead>
								<tr>
									<th>배송지명</th>
									<th>이름</th>
									<th>연락처</th>
									<th>주소</th>
								</tr>
								</thead>
								<tbody>
								<tr
									v-for="(item, s_index) in shipping_list"
									:key="'shipping_' + s_index"
								>
									<td>{{ item.shipping_name }}</td>
									<td>{{ item.name }}</td>
									<td>{{ item.tell }}</td>
									<td class="text-left">{{ item.addr1 }} {{ item.addr2 }}</td>
								</tr>
								</tbody>
							</table>
						</div>

						<Empty
							v-else
						></Empty>
					</div>
					<div
						v-else-if="tab == 3"
						class="full-height"
					>
						<div>
							<table class="table">
								<colgroup>
									<col width="120px" />
									<col width="auto" />
									<col width="180px" />
								</colgroup>
								<tbody>
								<tr>
									<th>포인트 지급</th>
									<td>
										<input v-model="item_point.use_point" class="input-box" placeholder="포인트를 입력하세요" type="number" :rules="[$rules.max(item_point, 'use_point', 9)]"/>
										<input v-model="item_point.memo" class="input-box ml-10" placeholder="지급내용을 입력하세요" maxlength="255"/>
									</td>
									<td>
										<button
											class="btn-inline btn-primary mr-10"
											@click="onPoint('+')"
											:disabled="is_disabled"
										>지급</button>
										<button
											class="btn-inline btn-default"
											@click="onPoint('-')"
											:disabled="is_disabled"
										>회수</button>
									</td>
								</tr>
								</tbody>
							</table>
						</div>
						<div
							v-if="point_list.length > 0"
						>
							<table class="table table-even">
								<colgroup>
									<col width="150px"/>
									<col width="110px"/>
									<col width="110px"/>
									<col width="110px"/>
									<col width="auto"/>
								</colgroup>
								<thead>
								<tr>
									<th>지급일자</th>
									<th>구분</th>
									<th>포인트</th>
									<th>잔여 포인트</th>
									<th>메모</th>
								</tr>
								</thead>
								<tbody>
								<tr
									v-for="(item, s_index) in point_list"
									:key="'point_' + s_index"
								>
									<td>{{ item.wDate }}</td>
									<td>{{ item.use_div_name }}</td>
									<td :class="item.use_type == '+' ? 'color-red' : 'color-blue'">{{ item.use_point | makeComma}}P</td>
									<td>{{ item.point_now | makeComma}}P</td>
									<td class="text-left">{{ item.memo }}</td>
								</tr>
								</tbody>
							</table>
						</div>

						<Empty
							v-else
						></Empty>
					</div>

					<div
						v-else-if="tab == 4"
						class="full-height flex-column overflow-y-auto"
					>
						<div
							v-if="history_list.length > 0"
							class="full-height flex-column overflow-y-auto"
						>
							<table class="table">
								<col width="180px" />
								<col width="auto" />
								<col width="80px" />
								<tbody>
								<template
									v-for="(history, index) in items_history"
								>
									<tr
										:key="history.uid + '_' + index"
										@click="history.is_show = !history.is_show"
									>
										<td>{{ history.wDate }} {{ item.is_show }}</td>
										<td class="text-left">{{ history.history_type }}</td>
										<td><i class="mdi size-px-20 cursor-pointer" :class="history.is_show ? 'mdi-menu-up': 'mdi-menu-down'"></i></td>
									</tr>
									<tr
										v-if="history.is_show"
										:key="history.uid + '_history_' + index"
									>
										<td colspan="3" class="text-left">
											{{ history.history }}
										</td>
									</tr>
								</template>
								</tbody>
							</table>
						</div>
						<Empty
							v-else
						></Empty>
					</div>

					<div
						v-else-if=" tab == 5"
					>
						<div
							v-if="grade_list.length > 0"
							class="full-height flex-column overflow-y-auto"
						>
							<table class="table">
								<col width="180px" />
								<col width="auto" />
								<col width="auto" />
								<tbody>
								<template
									v-for="(history, index) in items_grade"
								>
									<tr
										:key="history.uid + '_' + index"
										@click="history.is_show = !history.is_show"
									>
										<td>{{ history.wDate }} {{ item.is_show }}</td>
										<td>{{ history.type_name }}</td>
										<td colspan="3" class="td-left">
											{{ history.old_name }}
											->
											{{ history.grade_name }}
										</td>
									</tr>
								</template>
								</tbody>
							</table>
						</div>
						<Empty
							v-else
						></Empty>
					</div>
				</div>
			</div>
		</div>

		<div
			class="mt-10 justify-center"
		>
			<button
				class="bg-identify pa-10-20 mr-10"
				@click="save"
			>저장</button>
			<button
				class="box pa-10-20"
				@click="toList"
			>목록</button>
		</div>

		<DaumPost
			:overlay="is_post"
			:config="daum_config"
			@callBack="addPost"
		></DaumPost>

		<Modal
			:is_modal="is_modal"
			:option="{}"
			:top="true"
			:bottom="true"

			title="비밀번호 초기화"
			content="비밀번호를 초기화 하시겠습니까?"
			width="360px"

			content_class="ptb-50"

			@click="doPassword"
			@close="clear"
			@cancel="clear"
		></Modal>
	</div>
</template>

<script>

import DaumPost from "@/components/Daum/DaumPost";
import Modal from "@/components/Modal";
import Empty from "@/view/Layout/Empty";
export default {
	name: 'MemberDetail'
	,
	components: {Empty, Modal, DaumPost},
	props: ['Axios', 'user', 'codes', 'rules', 'TOKEN']
	,data: function(){
		return {
			program: {
				name: '회원 상세정보'
				, top: true
				, title: true
				, bottom: false
			}
			,item: {

			}
			, tab: 1
			, agency_list: []
			, shipping_list: []
			, items_order: []
			, history_list: []
			, grade_list: []
			, month: 0
			,is_modal: false
			,modal_option: {
				title: '비밀번호 초기화'
				,content: '비밀번호를 초기화 하고 등록된 연락처로 전송하시겠습니까?'
				,top: true
				,bottom: true
				,width: '360px'
			}
			,is_modal2: false
			,modal_option2: {
				title: '회원 계정상태 변경'
				,top: true
				,bottom: true
				,width: '360px'
			}
			,is_modal3: false
			,modal_option3: {
				title: '첨부파일 삭제'
				,top: true
				,bottom: true
				,content: '해당 첨부파일을 삭제하시겠습니까?'
				,width: '360px'
			}
			,is_post: false
			,daum_config: {
				width: '360px'
			}
			, items_point: []
			, item_point: {

			}
		}
	}
	,computed: {
		items_history: function(){
			return this.history_list.filter((item) => {

				this.$set(item, 'is_show', false)
				return item
			})
		}
		, items_grade: function(){

			return this.grade_list.filter((item) => {

				let history = item.history
				if(history){
					history = JSON.parse(history)
				}
				this.$set(item, 'is_show', false)
				item.type_name = history.type == 'admin' ? '관리자' : history.type == 'schedule' ? '자동 등업' : '-'
				this.codes.M001.items.filter( (code) => { if(code.code_index == history.old) item.old_name =  code.code_name })
				this.codes.M001.items.filter( (code) => { if(code.code_index == history.grade) item.grade_name = code.code_name})

				return item
			})
		}
		, point_list: function(){
			return this.items_point.filter((item) => {
				switch (item.use_div){
					case 'admin':
						item.use_div_name = '관리자 지급'
						break;
					default:
						break;
				}

				return item
			})
		}
		, is_disabled: function(){
			let t = true
			if(this.item_point.use_point > 0 && this.item_point.memo){
				t = false
			}
			return t
		}
		, list_order: function(){
			return this.items_order.filter( ( item ) => {
				switch (item.pay_div){
					case 'point':
						item.pay_div_name = '포인트'
						break;
					default:
						item.pay_div_name = '카드 결제'
						break;
				}

				return item
			})
		}
	}
	, methods: {
		getAgencyList: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getAgencyList'
					,data: {
						ATOKEN: this.TOKEN
						,agency_type: 'A001003'
					}
				})
				if(result.success){
					this.agency_list = result.data.result
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getMember'
					,data: {
						uid: this.$route.params.idx
					}
				})
				if(result.success){
					this.item = result.data.member_info
					this.shipping_list = result.data.shipping_list
					this.history_list = result.data.history
					this.items_point = result.data.point_list
					this.grade_list = result.data.grade
					this.month = result.data.month
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,save: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/putMember'
					,data: this.item
				})
				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: result.message})
					await this.getData()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,isPassword: function(){
			this.clear()
			this.is_modal = true
		}
		,isStatus: function(){
			this.clear()
			this.is_modal2 = true
		}
		,clear: function(){
			this.is_modal = false
			this.is_modal2 = false
			this.is_modal3 = false
		}
		,toList: function(){
			this.$router.back()
		}

		,daumPost: function () {
			this.is_post = true
		}
		, addPost: function (call) {

			this.$set(this.item, 'member_post', call.zonecode)
			this.$set(this.item, 'member_addr1', call.address)

			this.is_post = false
		}
		,doPassword: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postMemberPasswordReset'
					,data: {
						uid: this.$route.params.idx
					}
				})
				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: result.message})
					this.clear()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getOrderList: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getOrderList'
					,data: {
						member_id: this.item.member_id
					}
				})
				if(result.success){
					this.items_order = result.data.result
				}else{
					throw result.message
				}
			}catch(e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message:e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, do: async function(){
			await this.getAgencyList();

			await this.getData();

			await this.getOrderList();
		}
		, postPoint: async function(type){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postPoint'
					,data: {
						member_id: this.item.member_id
						, use_point: this.item_point.use_point
						, type: type
						, memo: this.item_point.memo
					}
				})
				if(result.success){
					this.item.member_point = result.data.member_point
					this.items_point = result.data.point_list
					this.item_point = {

					}
				}else{
					throw result.message
				}
			}catch(e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message:e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onPoint: function(type){
			if(confirm('해당 유저에게 ' + this.item_point.use_point + ' 포인트를 ' + (type == '+' ? '지급' : '차감') + '하시겠습니까?')){
				this.postPoint(type)
			}
		}
		, postRefresh: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postRefresh'
					,data: {
						member_id: this.item.member_id
					}
				})
				if(result.success){
					this.item.member_point = result.data.member_point
				}else{
					throw result.message
				}
			}catch(e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message:e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	, created() {
		this.$emit('onLoad', this.program)
		this.do()
	}
}
</script>

<style>
.width-fee { width: 60px !important; text-align: right;}

.crown- { background-color: #bbb}
.crown-bronze { background-color: #CD7F32 !important; color: #eee}
.crown-silver { background-color: silver !important; }
.crown-gold { background-color: gold !important; }
.crown-vip { background-color: violet !important; color: #eee}
</style>